<template>
  <div id="app" :class="{ show: show, first: isFirst }">
    <div id="webgl"></div>
    <div id="aboutTransition" :class="{ active: isAboutActive, show: isAboutMoved }">
      <span class="pageTransitionBg"></span>
      <span class="pageTransitionLine"></span>
      <div class="whoweare"><p>PROFILE</p><span></span></div>
    </div>
    <div class="toTop" v-if="isToTop" :class="{ show: isToTopActive }">
      <div class="wrap">
        <router-link to="/"><span></span>TOP</router-link>
      </div>
    </div>
    <section id="container" :class="{ open: isOpen }">
      <transition name="fade">
        <router-view/>
      </transition>
    </section>
  </div>
</template>

<script>
import * as THREE from 'three';
import $ from 'jquery';
let presentPage;
export default {
  name: 'App',
  data () {
    return {
      isActive: false,
      isOpen: false,
      isFirst: false,
      show: false,
      isWhite: false,
      current: window.location.pathname,
      page: '',
      isAboutActive: false,
      isAboutMoved: false,
      isToTop: false,
      isToTopActive: false
    };
  },
  methods: {
    toggle: function () {
      this.isActive = !this.isActive;
      this.isOpen = !this.isOpen;
    }
  },
  created () {
    let _this = this;
    let showDelay = false;
    if (this.$route.name !== 'home') {
      this.isWhite = true;
      this.isToTop = true;
    } else {
      this.isWhite = false;
      this.isToTop = false;
    }
    showDelay = setInterval(function () {
      clearInterval(showDelay);
      _this.show = true;
      _this.isFirst = true;
    }, 100);
  },
  watch: {
    '$route' (to) {
      let __this = this;
      this.isFirst = false;
      this.isOpen = false;
      this.isActive = false;
      this.page = this.$store.getters.getPage;
      if (to.name !== 'home') {
        this.isWhite = true;
      } else {
        this.isWhite = false;
      }
      if (this.page == 'about') {
        this.isAboutActive = true;
      }
      if (this.page != 'about') {
        this.isAboutActive = false;
      }
      if (to.name == 'home') {
        this.isAboutMoved = false;
        this.isToTop = false;
        this.$store.dispatch('setPage', 'home');
        $('body').addClass('home');
        $('body').removeClass('about');
      } else {
        this.isToTop = true;
      }
      if (to.name == 'about') {
        this.isAboutMoved = true;
        $('body').addClass('about');
        $('body').removeClass('home');
      }
      presentPage = to.name;

      if (this.isToTop) {
        setTimeout(function () {
          __this.isToTopActive = true;
        }, 10);
      }
    }
  }
};

window.addEventListener('load', function () { loaded(true); }, false);

function loaded () {

  let CanvasDetector = {
    canCanvas: function () {
      return !!window.CanvasRenderingContext2D;
    },
    canWebGL: function () {
      try {
        return !!window.WebGLRenderingContext && !!document.createElement('canvas').getContext('experimental-webgl');
      } catch (e) {
        return false;
      }
    }
  };

  let group;
  let container;
  let particlesData = [];
  let camera;
  let scene;
  let renderer;
  let positions;
  let colors;
  let particles;
  let pointCloud;
  let particlePositions;
  let linesMesh;

  let maxParticleCount = 800;
  let particleCount = 150;
  let r = 800;
  let rHalf = r / 2;

  let effectController = {
    showDots: true,
    showLines: true,
    minDistance: 200,
    limitConnections: false,
    maxConnections: 20,
    particleCount: 150
  };

  let tmp = 500;
  let rad = 0;

  if (CanvasDetector.canWebGL()) {
    init();
    animate();
  }

  function init () {
    let ua = window.navigator.userAgent.toLowerCase();

    container = document.getElementById('webgl');
    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 4000);
    camera.position.z = 500;

    scene = new THREE.Scene();

    group = new THREE.Group();
    scene.add(group);

    let segments = maxParticleCount * maxParticleCount;

    positions = new Float32Array(segments * 3);
    colors = new Float32Array(segments * 3);

    let pMaterial = new THREE.PointsMaterial({
      // color: 0x000000,
      vertexColors: THREE.VertexColors,
      size: 2,
      // blending: THREE.AdditiveBlending,
      transparent: true,
      sizeAttenuation: false
    });

    particles = new THREE.BufferGeometry();
    particlePositions = new Float32Array(maxParticleCount * 3);

    for (let i = 0; i < maxParticleCount; i++) {
      let x = Math.random() * r - r / 2;
      let y = Math.random() * r - r / 2;
      let z = Math.random() * r - r / 2;

      particlePositions[ i * 3 ] = x;
      particlePositions[ i * 3 + 1 ] = y;
      particlePositions[ i * 3 + 2 ] = z;

      // add it to the geometry
      particlesData.push({
        velocity: new THREE.Vector3(-1 + Math.random() * 2, -1 + Math.random() * 2, -1 + Math.random() * 2),
        numConnections: 0
      });

      colors[ i ] = Math.random();
    }

    particles.setDrawRange(0, particleCount);
    particles.addAttribute('position', new THREE.BufferAttribute(particlePositions, 3).setDynamic(true));
    particles.addAttribute('color', new THREE.BufferAttribute(colors, 3).setDynamic(true));

    // create the particle system
    pointCloud = new THREE.Points(particles, pMaterial);
    group.add(pointCloud);

    let geometry = new THREE.BufferGeometry();

    let lineOpacity = 0.35;

    if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
      lineOpacity = 0.38;
    }
    if (ua.match(/(msie|MSIE)/) || ua.match(/(T|t)rident/)) {
      lineOpacity = 0.1;
    }
    if (ua.indexOf('edge') !== -1) {
      lineOpacity = 0.1;
    }

    let material = new THREE.LineBasicMaterial({
      vertexColors: THREE.VertexColors,
      transparent: true,
      opacity: lineOpacity
    });

    linesMesh = new THREE.LineSegments(geometry, material);
    group.add(linesMesh);

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3).setDynamic(true));
    geometry.addAttribute('color', new THREE.BufferAttribute(colors, 3).setDynamic(true));

    geometry.computeBoundingSphere();

    geometry.setDrawRange(0, 0);

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0xffffff, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    renderer.gammaInput = true;
    renderer.gammaOutput = true;

    container.appendChild(renderer.domElement);

    window.addEventListener('resize', onWindowResize, false);

    function onWindowResize () {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }
  }

  function animate () {

    if (presentPage == 'home') {
      let vertexpos = 0;
      let colorpos = 0;
      let numConnected = 0;

      for (let n = 0; n < particleCount; n++) {
        particlesData[ n ].numConnections = 0;
      }

      for (let i = 0; i < particleCount; i++) {
        // get the particle
        let particleData = particlesData[i];

        particlePositions[ i * 3 ] += particleData.velocity.x;
        particlePositions[ i * 3 + 1 ] += particleData.velocity.y;
        particlePositions[ i * 3 + 2 ] += particleData.velocity.z;

        if (particlePositions[ i * 3 + 1 ] < -rHalf || particlePositions[ i * 3 + 1 ] > rHalf) {
          particleData.velocity.y = -particleData.velocity.y;
        }

        if (particlePositions[ i * 3 ] < -rHalf || particlePositions[ i * 3 ] > rHalf) {
          particleData.velocity.x = -particleData.velocity.x;
        }

        if (particlePositions[ i * 3 + 2 ] < -rHalf || particlePositions[ i * 3 + 2 ] > rHalf) {
          particleData.velocity.z = -particleData.velocity.z;
        }

        if (effectController.limitConnections && particleData.numConnections >= effectController.maxConnections) {
          continue;
        }

        for (let j = i + 1; j < particleCount; j++) {
          let particleDataB = particlesData[ j ];
          if (effectController.limitConnections && particleDataB.numConnections >= effectController.maxConnections) {
            continue;
          }

          let dx = particlePositions[ i * 3 ] - particlePositions[ j * 3 ];
          let dy = particlePositions[ i * 3 + 1 ] - particlePositions[ j * 3 + 1 ];
          let dz = particlePositions[ i * 3 + 2 ] - particlePositions[ j * 3 + 2 ];
          let dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

          if (dist < effectController.minDistance) {
            particleData.numConnections++;
            particleDataB.numConnections++;

            // let alpha = 1 - dist / effectController.minDistance;

            positions[ vertexpos++ ] = particlePositions[i * 3];
            positions[ vertexpos++ ] = particlePositions[i * 3 + 1];
            positions[ vertexpos++ ] = particlePositions[i * 3 + 2];

            positions[ vertexpos++ ] = particlePositions[j * 3];
            positions[ vertexpos++ ] = particlePositions[j * 3 + 1];
            positions[ vertexpos++ ] = particlePositions[j * 3 + 2];

            colors[ colorpos++ ] = Math.random();
            colors[ colorpos++ ] = Math.random();
            colors[ colorpos++ ] = Math.random();

            colors[ colorpos++ ] = Math.random();
            colors[ colorpos++ ] = Math.random();
            colors[ colorpos++ ] = Math.random();

            colors[ colorpos++ ] = Math.random();
            colors[ colorpos++ ] = Math.random();
            colors[ colorpos++ ] = Math.random();
            numConnected++;
          }
        }
      }

      linesMesh.geometry.setDrawRange(0, numConnected * 2);
      linesMesh.geometry.attributes.position.needsUpdate = true;
      linesMesh.geometry.attributes.color.needsUpdate = true;
      pointCloud.geometry.attributes.position.needsUpdate = true;

      //requestAnimationFrame(animate);
      render();
    }

    requestAnimationFrame(animate);

  }

  function render () {
    let time = Date.now() * 0.001;
    group.rotation.y = time * 0.3;
    // camera.position.z += (1000 - camera.position.z) * 0.01; イージング

    if (rad < 360) {
      rad += 0.4;
      tmp = Math.sin(rad * (Math.PI / 180));
      camera.position.z = (400 * tmp) + 600;
    } else {
      rad = 0;
    }
    renderer.render(scene, camera);
  }
}

</script>

<style lang="scss">
.pc {
  display: none !important;
  @media screen and (min-width:640px) {
    display: block !important;
  }
  &.inline {
    display: none !important;
    @media screen and (min-width:640px) {
      display: inline-block !important;
    }
  }
}
.sp {
  display: block !important;
  @media screen and (min-width:640px) {
    display: none !important;
  }
  &.inline {
    display: inline-block !important;
    @media screen and (min-width:640px) {
      display: none !important;
    }
  }
}

body {
  transition: background-color .5s ease;
}

body.about {
  background-color: #000;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fade-enter-active,
.fade-leave-active{
  transition: opacity 1s;
}
.fade-enter{
  opacity: 0;
}
.fade-enter-to{
  opacity: 1;
}
.fade-leave{
  opacity: 1;
}
.fade-leave-to{
  opacity: 0;
}
#app{
  width: 100%;
}
.toTop {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  background: #000;
  height: 70px;
  z-index: 2;
  opacity: 0;
  transition: all .5s .5s ease;
  &.show {
    opacity: 1;
  }
  .wrap {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    a {
      position: relative;
      display: inline-block;
      margin-left: 44px;
      color: #fff;
      text-decoration: none;
      letter-spacing: 2px;
      font-size: .9rem;
      @media screen and (min-width:640px) {
        margin-left: 26px;
        font-size: 1rem;
      }
      span {
        position: absolute;
        display: block;
        top: calc(50% - .5px);
        left: -24px;
        width: 14px;
        height: 1px;
        background-color: #fff;
        animation: lineToTop 1s ease-in-out infinite;
      }
      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 1px;
        left: -25px;
        background-color: #fff;
      }
      &:before {
        top: 5px;
        transform: rotate(-45deg);
        @media screen and (min-width:640px) {
          top: 6px;
        }
      }
      &:after {
        bottom: 5px;
        transform: rotate(45deg);
        @media screen and (min-width:640px) {
          bottom: 6px;
        }
      }
    }
  }
}
#aboutTransition {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  .pageTransitionBg {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    top: calc(50% + 1px);
    left: 0;
    background: #000;
    transition: all .5s 1.6s ease;
    @media screen and (min-width:640px) {
      transition: all .6s 1.7s ease;
    }
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 50%;
    left: 0;
    background: #000;
    transition: all .5s 1.6s ease;
    @media screen and (min-width:640px) {
      transition: all .6s 1.7s ease;
    }
  }
  .pageTransitionLine {
    position: absolute;
    display: block;
    width: 0;
    height: 1px;
    background: #000;
    transition: all .75s .6s ease;
    @media screen and (min-width:640px) {
      transition: all 1s .6s ease;
    }
  }
  .whoweare {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: top .6s 1.6s ease, height .6s 1.6s ease;
    @media screen and (min-width:640px) {
      transition: top .6s 1.7s ease, height .6s 1.7s ease;
    }
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 6rem;
      color: #fff;
      text-align: center;
      @media screen and (min-width:640px) {
        font-size: 10rem;
      }
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #000;
      transition: width 1s 2.5s cubic-bezier(.95, .05, .795, .035);
      @media screen and (min-width:640px) {
        transition: width 1.4s 2.5s cubic-bezier(.73,0,.66,.66);
      }
    }
  }
}
#aboutTransition.active {
  height: 100%;
  z-index: 2;
  .pageTransitionBg {
    height: calc(50% + 1px);
    top: 0;
  }
  &:before {
    height: 50%;
    bottom: 0;
  }
  .pageTransitionLine {
    width: 100%;
  }
  .whoweare {
    top: 0;
    height: 100%;
    span {
      width: 100%;
    }
  }
}

#aboutTransition.show {
  z-index: -1;
}

#container{
  width:100%;
  position:absolute;
  width: 100%;
  height: 100%;
  -webkit-transform:translate(0,0);
  -moz-transform:translate(0,0);
  -ms-transform:translate(0,0);
  -webkit-transition-duration:.5s;
  -moz-transition-duration:.5s;
  -ms-transition-duration:.5s;
  transition-duration:.5s;
  -webkit-transition-timing-function:cubic-bezier(.78,.01,.37,1);
  -moz-transition-timing-function:cubic-bezier(.78,.01,.37,1);
  -ms-transition-timing-function:cubic-bezier(.78,.01,.37,1);
  transition-timing-function:cubic-bezier(.78,.01,.37,1);
  overflow: auto;
}
#app #webgl{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
  -webkit-transition-delay: 3s;
  -moz-transition-delay: 3s;
  -ms-transition-delay: 3s;
  transition-delay: 3s;
}
#app #top #contents #logo_area {
  position: relative;
  width: 200px;
  margin: 0 auto 2rem;
  padding-top: 200px;
  transition: padding-top .5s ease-out;
  transition-delay: 2.6s;
  opacity: 0;
}
#app.show #top #contents #logo_area{
  padding-top: 0;
  opacity: 1;
}
#app.show #webgl{
  opacity: 1;
}
.btns {
  display: flex;
  justify-content: center;
  width: 100%;
  ul li {
    position: relative;
    display: block;
    width: 130px;
    height: 40px;
    div {
      position: absolute;
      width: 0;
      height: 100%;
      left: 0;
      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #000;
        animation: line1 1s ease-in-out infinite;
      }
      a{
        position: absolute;
        width: 130px;
        text-decoration: none;
        padding: 10px 0;
        span {
          font-weight: bold;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}
@media screen and (min-width:640px) {
  #container {
    overflow: hidden;
  }
  body.about #container {
    position: relative;
  }
  #app #top #contents #logo_area{
    padding-top: 80px;
    width: 300px;
    margin: 0 auto 35px;
  }
  #app #top #contents #logo_area .logo{
    height: 62px;
  }
  #app #top #contents #logo_area .sub{
    height: 18px;
    margin-top: 8px;
  }
}
@-webkit-keyframes fade {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fade {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-webkit-keyframes spin {
  0% {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
}
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
@keyframes line1 {
  0% {
    width: 0;
    left: 0;
  }
  40% {
    width: 100%;
    left: 0;
  }
  60% {
    width: 100%;
    left: 0;
  }
  90% {
    width: 0;
    left: 100%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes lineToTop {
  0% {
    width: 0;
    left: -7px;
  }
  40% {
    width: 15px;
    left: -24px;
  }
  60% {
    width: 15px;
    left: -24px;
  }
  90% {
    width: 0;
    left: -24px;
  }
  100% {
    width: 0;
    left: -24px;
  }
}
</style>
