<template>
  <div id="about">
    <div class="aboutContents" :class="{ show: state.isShow }">
      <section class="description" :class="{ center: state.isDescriptionCenter }">
        <div class="wrap">
          <h1>Webサービス開発を中心に活動しているクリエティブデベロッパーです。2011年の設立以降、企業、個人問わず多くのプロジェクトに参画し、デザイン制作からシステム開発まで幅広い領域でサービスを提供してきました。
            フロントエンドからバックエンドまで一括に請け負うワンストップ開発から、個別開発まで柔軟な開発環境を整えております。<br>
            <span class="en">
              We are a creative developer focusing on web service development, and since our establishment in 2011, we have participated in many projects for both corporations and individuals, providing services in a wide range of areas from design production to system development. We offer a flexible development environment from one-stop development to individual development, from front-end to back-end.
            </span>
          </h1>
          <div class="btns">
            <ul>
              <li class="contact"><div><a href="mailto:info@slip-case.com"><p class="name"><span>CONTACT</span></p></a></div></li>
            </ul>
          </div>
          <span class="scroll">SCROLL</span>
        </div>
      </section>

      <section id="frontendEngineering" class="block">
        <h3>
          <span class="svg pc">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 420 26" style="enable-background:new 0 0 420 26;" xml:space="preserve">
              <g id="layer1">
                <g>
                  <path class="st2 svg-elem-2" d="M15,15.4H5.2v10.3H0V0.5h16.2v4.2H5.2v6.5H15V15.4z"></path>
                  <path class="st2 svg-elem-3" d="M28.5,16.5h-4.1v9.3h-5.1V0.5h9.3c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.2-1.1,4.3
                  c-0.7,1.2-1.9,2.1-3.3,2.8l5.4,10.3v0.2h-5.5L28.5,16.5z M24.4,12.3h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.8
                  c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.3z"></path>
                  <path class="st2 svg-elem-4" d="M62.1,13.7c0,2.5-0.4,4.7-1.3,6.6c-0.9,1.9-2.1,3.3-3.7,4.3c-1.6,1-3.5,1.5-5.6,1.5c-2.1,0-3.9-0.5-5.5-1.5
                  c-1.6-1-2.9-2.4-3.8-4.3s-1.3-4-1.4-6.5v-1.3c0-2.5,0.4-4.7,1.3-6.6c0.9-1.9,2.1-3.3,3.8-4.4c1.6-1,3.5-1.5,5.5-1.5
                  c2.1,0,3.9,0.5,5.5,1.5c1.6,1,2.9,2.5,3.8,4.4c0.9,1.9,1.3,4.1,1.3,6.6V13.7z M56.9,12.5c0-2.7-0.5-4.7-1.4-6
                  c-0.9-1.4-2.3-2.1-4-2.1c-1.7,0-3.1,0.7-4,2c-0.9,1.4-1.4,3.4-1.4,6v1.2c0,2.6,0.5,4.6,1.4,6c0.9,1.4,2.3,2.1,4,2.1
                  c1.7,0,3-0.7,4-2.1c0.9-1.4,1.4-3.4,1.4-6V12.5z"></path>
                  <path class="st2 svg-elem-5" d="M86.1,25.8H81L71,9.1v16.6h-5.1V0.5H71l10,16.7V0.5h5.1V25.8z"></path>
                  <path class="st2 svg-elem-6" d="M109.4,4.7h-7.6v21.1h-5.1V4.7h-7.5V0.5h20.3V4.7z"></path>
                  <path class="st2 svg-elem-7" d="M119.8,17h-9.6v-4h9.6V17z"></path>
                  <path class="st2 svg-elem-8" d="M139.1,14.8h-9.9v6.8h11.6v4.2h-16.7V0.5h16.7v4.2h-11.5v6h9.9V14.8z"></path>
                  <path class="st2 svg-elem-9" d="M164.1,25.8H159L149,9.1v16.6h-5.1V0.5h5.1l10,16.7V0.5h5.1V25.8z"></path>
                  <path class="st2 svg-elem-10" d="M168.6,25.8V0.5h7.7c2.2,0,4.2,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.3c1,1.8,1.5,3.9,1.5,6.3v1.2
                  c0,2.4-0.5,4.4-1.4,6.3c-1,1.8-2.3,3.2-4,4.3c-1.7,1-3.7,1.5-5.9,1.5H168.6z M173.8,4.7v16.9h2.5c2,0,3.5-0.7,4.6-2
                  c1.1-1.3,1.6-3.2,1.6-5.7v-1.3c0-2.6-0.5-4.5-1.6-5.8c-1.1-1.3-2.6-2-4.6-2H173.8z"></path>
                  <path class="st2 svg-elem-11" d="M215.1,14.8h-9.9v6.8h11.6v4.2h-16.7V0.5h16.7v4.2h-11.5v6h9.9V14.8z"></path>
                  <path class="st2 svg-elem-12" d="M240.1,25.8H235L225,9.1v16.6h-5.1V0.5h5.1l10,16.7V0.5h5.1V25.8z"></path>
                  <path class="st2 svg-elem-13" d="M264.4,22.6c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-1-2.9-2.3-3.8-4.2
                  c-0.9-1.8-1.3-4-1.4-6.4v-1.7c0-2.5,0.4-4.7,1.3-6.6c0.8-1.8,2-3.3,3.6-4.2c1.6-1,3.4-1.5,5.6-1.5c3,0,5.3,0.7,6.9,2.1
                  c1.7,1.4,2.7,3.5,3,6.2h-5c-0.2-1.4-0.7-2.5-1.5-3.2c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.9v1.6
                  c0,2.7,0.5,4.7,1.5,6c1,1.4,2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.3v-4.4h-4.7v-3.8h9.8V22.6z"></path>
                  <path class="st2 svg-elem-14" d="M274,25.8h-5.1V0.5h5.1V25.8z"></path>
                  <path class="st2 svg-elem-15" d="M298.8,25.6h-5.1l-10-16.4v16.4h-5.1V0.7h5.1l10,16.4V0.7h5.1V25.6z"></path>
                  <path class="st2 svg-elem-16" d="M318.3,14.8h-9.8v6.7H320v4.1h-16.7V0.7H320v4.2h-11.5v5.9h9.8V14.8z"></path>
                  <path class="st2 svg-elem-17" d="M338.2,14.8h-9.9v6.8h11.6v4.2h-16.7V0.5h16.7v4.2h-11.5v6h9.9V14.8z"></path>
                  <path class="st2 svg-elem-18" d="M351.9,16.5h-4.1v9.1h-5.1V0.7h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                  c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L351.9,16.5z M347.8,12.3h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                  c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.3z"></path>
                  <path class="st2 svg-elem-19" d="M370.5,25.6h-5.1V0.7h5.1V25.6z"></path>
                  <path class="st2 svg-elem-20" d="M395.5,25.6h-5.1l-10-16.4v16.4h-5.1V0.7h5.1l10,16.4V0.7h5.1V25.6z"></path>
                  <path class="st2 svg-elem-21" d="M419.6,22.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                  c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                  c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                  c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V22.4z"></path>
                </g>
              </g>
            </svg>
          </span>

          <span class="svg sp">
            <svg class="" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 227.3 61.2" style="enable-background:new 0 0 227.3 61.2;" xml:space="preserve">
              <g>
                <path class="st0 svg-elem-1" d="M15.5,15.3H5.6v10.2H0.5V0.6h16.2v4.2H5.6v6.4h9.8V15.3z"></path>
                <path class="st0 svg-elem-2" d="M29.6,16.4h-4.1v9.1h-5.1V0.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L29.6,16.4z M25.5,12.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.2z"></path>
                <path class="st0 svg-elem-3" d="M63.9,13.6c0,2.4-0.4,4.6-1.3,6.4c-0.9,1.8-2.1,3.3-3.7,4.3c-1.6,1-3.5,1.5-5.5,1.5c-2.1,0-3.9-0.5-5.5-1.5
                c-1.6-1-2.9-2.4-3.8-4.2s-1.3-4-1.4-6.3v-1.2c0-2.4,0.4-4.6,1.3-6.5c0.9-1.9,2.1-3.3,3.7-4.3c1.6-1,3.5-1.5,5.5-1.5
                c2.1,0,3.9,0.5,5.5,1.5c1.6,1,2.9,2.4,3.7,4.3c0.9,1.9,1.3,4,1.3,6.5V13.6z M58.7,12.5c0-2.6-0.5-4.6-1.4-5.9c-0.9-1.4-2.3-2-4-2
                c-1.7,0-3,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.9v1.2c0,2.5,0.5,4.5,1.4,5.9c0.9,1.4,2.3,2.1,4,2.1c1.7,0,3-0.7,4-2
                c0.9-1.3,1.4-3.3,1.4-5.9V12.5z"></path>
                <path class="st0 svg-elem-4" d="M88.5,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-5" d="M111.9,4.8h-7.6v20.7h-5.1V4.8h-7.5V0.6h20.3V4.8z"></path>
                <path class="st0 svg-elem-6" d="M120.2,16.8h-9.6v-4h9.6V16.8z"></path>
                <path class="st0 svg-elem-7" d="M140.2,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"></path>
                <path class="st0 svg-elem-8" d="M165.8,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-9" d="M171,25.5V0.6h7.7c2.2,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.2s1.5,3.9,1.5,6.2v1.1c0,2.3-0.5,4.4-1.4,6.2
                c-1,1.8-2.3,3.2-4,4.2c-1.7,1-3.7,1.5-5.9,1.5H171z M176.1,4.8v16.6h2.5c2,0,3.5-0.7,4.6-2s1.6-3.2,1.6-5.6v-1.3
                c0-2.5-0.5-4.4-1.6-5.8c-1-1.3-2.6-2-4.6-2H176.1z"></path>
                <path class="st0 svg-elem-10" d="M15.5,49.7H5.6v6.7h11.6v4.1H0.5V35.6h16.6v4.2H5.6v5.9h9.8V49.7z"></path>
                <path class="st0 svg-elem-11" d="M41.1,60.5H36L26,44.1v16.4h-5.1V35.6H26L36,52V35.6h5.1V60.5z"></path>
                <path class="st0 svg-elem-12" d="M66,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8H66V57.4z"></path>
                <path class="st0 svg-elem-13" d="M76.3,60.5h-5.1V35.6h5.1V60.5z"></path>
                <path class="st0 svg-elem-14" d="M102,60.5h-5.1l-10-16.4v16.4h-5.1V35.6h5.1l10,16.4V35.6h5.1V60.5z"></path>
                <path class="st0 svg-elem-15" d="M122.1,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"></path>
                <path class="st0 svg-elem-16" d="M142.5,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"></path>
                <path class="st0 svg-elem-17" d="M157.2,51.4h-4.1v9.1H148V35.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L157.2,51.4z M153.1,47.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V47.2z"></path>
                <path class="st0 svg-elem-18" d="M176.4,60.5h-5.1V35.6h5.1V60.5z"></path>
                <path class="st0 svg-elem-19" d="M202.1,60.5H197l-10-16.4v16.4h-5.1V35.6h5.1L197,52V35.6h5.1V60.5z"></path>
                <path class="st0 svg-elem-20" d="M227,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V57.4z"></path>
              </g>
            </svg>
          </span>

          <span class="clip pc">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 420 26" style="enable-background:new 0 0 420 26;" xml:space="preserve">
              <clipPath id="frontendEngineeringClip1PC" clipPathUnits="objectBoundingBox">
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M15.4,15.4H5.6v10.2H0.4V0.7h16.2v4.2H5.6v6.4h9.8V15.4z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M28.5,16.5h-4.1v9.3h-5.1V0.5h9.3c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.2-1.1,4.3
                c-0.7,1.2-1.9,2.1-3.3,2.8l5.4,10.3v0.2h-5.5L28.5,16.5z M24.4,12.3h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.8
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.3z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M62.4,13.7c0,2.4-0.4,4.6-1.3,6.4c-0.9,1.8-2.1,3.3-3.7,4.3c-1.6,1-3.5,1.5-5.5,1.5c-2.1,0-3.9-0.5-5.5-1.5
                c-1.6-1-2.9-2.4-3.8-4.2s-1.3-4-1.4-6.3v-1.2c0-2.4,0.4-4.6,1.3-6.5c0.9-1.9,2.1-3.3,3.7-4.3c1.6-1,3.5-1.5,5.5-1.5
                c2.1,0,3.9,0.5,5.5,1.5c1.6,1,2.9,2.4,3.7,4.3c0.9,1.9,1.3,4,1.3,6.5V13.7z M57.2,12.6c0-2.6-0.5-4.6-1.4-5.9c-0.9-1.4-2.3-2-4-2
                c-1.7,0-3,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.9v1.2c0,2.5,0.5,4.5,1.4,5.9c0.9,1.4,2.3,2.1,4,2.1c1.7,0,3-0.7,4-2
                c0.9-1.3,1.4-3.3,1.4-5.9V12.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M86.4,25.6h-5.1l-10-16.4v16.4h-5.1V0.7h5.1l10,16.4V0.7h5.1V25.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M109.6,4.8h-7.6v20.7h-5.1V4.8h-7.5V0.7h20.3V4.8z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M120,16.9h-9.6v-4h9.6V16.9z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M139.3,14.8h-9.8v6.7H141v4.1h-16.7V0.7h16.6v4.2h-11.5v5.9h9.8V14.8z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M164.2,25.6h-5.1l-10-16.4v16.4H144V0.7h5.1l10,16.4V0.7h5.1V25.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M168.7,25.6V0.7h7.7c2.2,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.2c1,1.8,1.5,3.9,1.5,6.2v1.1
                c0,2.3-0.5,4.4-1.4,6.2s-2.3,3.2-4,4.2c-1.7,1-3.7,1.5-5.9,1.5H168.7z M173.8,4.8v16.6h2.5c2,0,3.5-0.7,4.6-2
                c1.1-1.3,1.6-3.2,1.6-5.6v-1.3c0-2.5-0.5-4.4-1.6-5.8c-1-1.3-2.6-2-4.6-2H173.8z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M215.1,14.8h-9.8v6.7h11.6v4.1h-16.7V0.7h16.6v4.2h-11.5v5.9h9.8V14.8z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M240.1,25.6H235L225,9.2v16.4h-5.1V0.7h5.1l10,16.4V0.7h5.1V25.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M264.3,22.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V22.4z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M273.9,25.6h-5.1V0.7h5.1V25.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M298.8,25.6h-5.1l-10-16.4v16.4h-5.1V0.7h5.1l10,16.4V0.7h5.1V25.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M318.3,14.8h-9.8v6.7H320v4.1h-16.7V0.7H320v4.2h-11.5v5.9h9.8V14.8z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M338,14.8h-9.8v6.7h11.6v4.1H323V0.7h16.6v4.2h-11.5v5.9h9.8V14.8z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M351.9,16.5h-4.1v9.1h-5.1V0.7h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L351.9,16.5z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M370.5,25.6h-5.1V0.7h5.1V25.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M395.5,25.6h-5.1l-10-16.4v16.4h-5.1V0.7h5.1l10,16.4V0.7h5.1V25.6z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M419.6,22.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V22.4z"/>
              </clipPath>
              <clipPath id="frontendEngineeringClip2PC" clipPathUnits="objectBoundingBox">
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M24.4,12.3h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.8
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.3z"/>
                <path class="st0" transform="scale(0.002380952380952, 0.038461538461538)" d="M347.8,12.3h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.3z"/>
              </clipPath>
            </svg>
          </span>

          <span class="clip sp">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 227.3 61.2" style="enable-background:new 0 0 227.3 61.2;" xml:space="preserve">
              <clipPath id="frontendEngineeringClip1SP" clipPathUnits="objectBoundingBox">
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M15.5,15.3H5.6v10.2H0.5V0.6h16.2v4.2H5.6v6.4h9.8V15.3z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M29.6,16.4h-4.1v9.1h-5.1V0.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L29.6,16.4z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M63.9,13.6c0,2.4-0.4,4.6-1.3,6.4c-0.9,1.8-2.1,3.3-3.7,4.3c-1.6,1-3.5,1.5-5.5,1.5c-2.1,0-3.9-0.5-5.5-1.5
                c-1.6-1-2.9-2.4-3.8-4.2s-1.3-4-1.4-6.3v-1.2c0-2.4,0.4-4.6,1.3-6.5c0.9-1.9,2.1-3.3,3.7-4.3c1.6-1,3.5-1.5,5.5-1.5
                c2.1,0,3.9,0.5,5.5,1.5c1.6,1,2.9,2.4,3.7,4.3c0.9,1.9,1.3,4,1.3,6.5V13.6z M58.7,12.5c0-2.6-0.5-4.6-1.4-5.9c-0.9-1.4-2.3-2-4-2
                c-1.7,0-3,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.9v1.2c0,2.5,0.5,4.5,1.4,5.9c0.9,1.4,2.3,2.1,4,2.1c1.7,0,3-0.7,4-2
                c0.9-1.3,1.4-3.3,1.4-5.9V12.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M88.5,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M111.9,4.8h-7.6v20.7h-5.1V4.8h-7.5V0.6h20.3V4.8z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M120.2,16.8h-9.6v-4h9.6V16.8z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M140.2,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M165.8,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M171,25.5V0.6h7.7c2.2,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.2s1.5,3.9,1.5,6.2v1.1c0,2.3-0.5,4.4-1.4,6.2
                c-1,1.8-2.3,3.2-4,4.2c-1.7,1-3.7,1.5-5.9,1.5H171z M176.1,4.8v16.6h2.5c2,0,3.5-0.7,4.6-2s1.6-3.2,1.6-5.6v-1.3
                c0-2.5-0.5-4.4-1.6-5.8c-1-1.3-2.6-2-4.6-2H176.1z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M15.5,49.7H5.6v6.7h11.6v4.1H0.5V35.6h16.6v4.2H5.6v5.9h9.8V49.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M41.1,60.5H36L26,44.1v16.4h-5.1V35.6H26L36,52V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M66,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8H66V57.4z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M76.3,60.5h-5.1V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M102,60.5h-5.1l-10-16.4v16.4h-5.1V35.6h5.1l10,16.4V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M122.1,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M142.5,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M157.2,51.4h-4.1v9.1H148V35.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L157.2,51.4z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M176.4,60.5h-5.1V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M202.1,60.5H197l-10-16.4v16.4h-5.1V35.6h5.1L197,52V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M227,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V57.4z"/>
              </clipPath>
              <clipPath id="frontendEngineeringClip2SP" clipPathUnits="objectBoundingBox">
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M25.5,12.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.2z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M153.1,47.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V47.2z"/>
              </clipPath>
            </svg>
          </span>

          <div id="particle1" class="inview">
            <div id="canvas1"></div>
          </div>
          <div class="white inview"></div>
          <div class="black"></div>
        </h3>

        <dl>
          <dt class="inview activeAnimation">
            <span class="text"><span class="wrap"><strong>JavaScript</strong></span></span>
            <span class="line"></span>
          </dt>
          <dd>
            <ul class="inview">
              <li class="inview"><span>Vue</span></li>
              <li class="inview"><span>React</span></li>
              <li class="inview"><span>Angular</span></li>
              <li class="inview"><span>Nuxt</span></li>
              <li class="inview"><span>jQuery</span></li>
              <li class="inview"><span>p5.js</span></li>
              <li class="inview"><span>TypeScript</span></li>
              <li class="inview"><span>Node</span></li>
              <li class="inview"><span>Three.js</span></li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt class="inview activeAnimation">
            <span class="text"><span class="wrap"><strong>Module<span style="margin: 0 0 0 5px;"></span>Bundler</strong></span></span>
            <span class="line"></span>
          </dt>
          <dd>
            <ul class="inview activeAnimation">
              <li class="inview activeAnimation"><span>Webpack</span></li>
              <li class="inview activeAnimation"><span>Gulp</span></li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt class="inview activeAnimation">
            <span class="text"><span class="wrap"><strong>Markup</strong></span></span>
            <span class="line"></span>
          </dt>
          <dd>
            <ul class="inview">
              <li class="inview"><span>HTML5</span></li>
              <li class="inview"><span>CSS3</span></li>
            </ul>
          </dd>
        </dl>
      </section>

      <section id="backendEngineering" class="block">
        <h3>
          <span class="svg pc">
            <svg class="" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 398.7 26" style="enable-background:new 0 0 398.7 26;" xml:space="preserve">
              <g>
                <path class="st0 svg-elem-1" d="M0.4,25.5V0.6h8.7c3,0,5.3,0.6,6.9,1.7c1.6,1.2,2.3,2.9,2.3,5.1c0,1.2-0.3,2.3-0.9,3.2c-0.6,0.9-1.5,1.6-2.6,2
                c1.3,0.3,2.3,1,3,1.9c0.7,1,1.1,2.2,1.1,3.6c0,2.4-0.8,4.2-2.3,5.4c-1.5,1.2-3.7,1.9-6.5,1.9H0.4z M5.6,11h3.8
                c2.6,0,3.9-1.1,3.9-3.1c0-1.1-0.3-1.9-1-2.4C11.6,5,10.6,4.7,9.2,4.7H5.6V11z M5.6,14.6v6.7H10c1.2,0,2.2-0.3,2.8-0.9
                c0.7-0.6,1-1.4,1-2.4c0-2.3-1.2-3.4-3.5-3.5H5.6z"></path>
                <path class="st0 svg-elem-2" d="M36.7,20.3h-9L26,25.5h-5.5l9.3-24.9h4.8l9.3,24.9h-5.5L36.7,20.3z M29.1,16.2h6.2l-3.1-9.3L29.1,16.2z"></path>
                <path class="st0 svg-elem-3" d="M65.7,17.2c-0.2,2.7-1.2,4.8-3,6.3c-1.8,1.5-4.1,2.3-7,2.3c-3.2,0-5.7-1.1-7.5-3.2c-1.8-2.1-2.7-5.1-2.7-8.8
                v-1.5c0-2.4,0.4-4.5,1.3-6.3c0.8-1.8,2-3.2,3.6-4.2c1.6-1,3.4-1.5,5.5-1.5c2.9,0,5.2,0.8,6.9,2.3c1.8,1.5,2.8,3.7,3,6.5h-5.1
                c-0.1-1.6-0.6-2.8-1.3-3.5c-0.8-0.7-1.9-1.1-3.5-1.1c-1.7,0-3,0.6-3.8,1.8C51,7.5,50.6,9.4,50.6,12v1.9c0,2.7,0.4,4.7,1.2,5.9
                s2.1,1.9,3.9,1.9c1.6,0,2.8-0.4,3.5-1.1c0.8-0.7,1.2-1.8,1.3-3.4H65.7z"></path>
                <path class="st0 svg-elem-4" d="M76.7,15.5L74,18.4v7.1h-5.1V0.6H74v11.3l2.3-3.1l6.3-8.2h6.3l-8.8,11.1l9.1,13.8h-6.1L76.7,15.5z"></path>
                <path class="st0 svg-elem-5" d="M100.3,16.8h-9.6v-4h9.6V16.8z"></path>
                <path class="st0 svg-elem-6" d="M119.5,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"></path>
                <path class="st0 svg-elem-7" d="M144.3,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-8" d="M148.7,25.5V0.6h7.7c2.2,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.2c1,1.8,1.5,3.9,1.5,6.2v1.1
                c0,2.3-0.5,4.4-1.4,6.2c-1,1.8-2.3,3.2-4,4.2c-1.7,1-3.7,1.5-5.9,1.5H148.7z M153.8,4.7v16.6h2.5c2,0,3.5-0.7,4.6-2
                s1.6-3.2,1.6-5.6v-1.3c0-2.5-0.5-4.4-1.6-5.8c-1-1.3-2.6-2-4.6-2H153.8z"></path>
                <path class="st0 svg-elem-9" d="M194.9,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"></path>
                <path class="st0 svg-elem-10" d="M219.8,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-11" d="M243.8,22.3c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                s-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1c1.7,1.4,2.6,3.5,3,6.1
                h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6c0,2.6,0.5,4.6,1.5,5.9
                s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3H234v-3.8h9.8V22.3z"></path>
                <path class="st0 svg-elem-12" d="M253.3,25.5h-5.1V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-13" d="M278.2,25.5h-5.1l-10-16.4v16.4H258V0.6h5.1l10,16.4V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-14" d="M297.5,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"></path>
                <path class="st0 svg-elem-15" d="M317.1,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"></path>
                <path class="st0 svg-elem-16" d="M330.9,16.4h-4.1v9.1h-5.1V0.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L330.9,16.4z M326.9,12.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.2z"></path>
                <path class="st0 svg-elem-17" d="M349.4,25.5h-5.1V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-18" d="M374.3,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"></path>
                <path class="st0 svg-elem-19" d="M398.4,22.3c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                s-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1c1.7,1.4,2.6,3.5,3,6.1
                h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6c0,2.6,0.5,4.6,1.5,5.9
                s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V22.3z"></path>
              </g>
            </svg>
          </span>

          <span class="svg sp">
            <svg class="" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 227.3 61.2" style="enable-background:new 0 0 227.3 61.2;" xml:space="preserve">

            <g>
              <path class="st0 svg-elem-1" d="M0.5,25.5V0.6h8.7c3,0,5.3,0.6,6.9,1.7c1.6,1.2,2.3,2.9,2.3,5.1c0,1.2-0.3,2.3-0.9,3.2c-0.6,0.9-1.5,1.6-2.6,2
              c1.3,0.3,2.3,1,3,1.9c0.7,1,1.1,2.2,1.1,3.6c0,2.4-0.8,4.2-2.3,5.4c-1.5,1.2-3.7,1.9-6.5,1.9H0.5z M5.6,11h3.8
              c2.6,0,3.9-1.1,3.9-3.1c0-1.1-0.3-1.9-1-2.4C11.7,5,10.6,4.8,9.2,4.8H5.6V11z M5.6,14.7v6.7H10c1.2,0,2.2-0.3,2.8-0.9
              c0.7-0.6,1-1.4,1-2.4c0-2.3-1.2-3.4-3.5-3.5H5.6z"></path>
              <path class="st0 svg-elem-2" d="M37.6,20.4h-9l-1.7,5.1h-5.5l9.3-24.9h4.8l9.3,24.9h-5.5L37.6,20.4z M30,16.2h6.2l-3.1-9.3L30,16.2z"></path>
              <path class="st0 svg-elem-3" d="M67,17.2c-0.2,2.7-1.2,4.8-3,6.3s-4.1,2.3-7,2.3c-3.2,0-5.7-1.1-7.5-3.2c-1.8-2.1-2.7-5.1-2.7-8.8v-1.5
              c0-2.4,0.4-4.5,1.3-6.3c0.8-1.8,2-3.2,3.6-4.2c1.6-1,3.4-1.5,5.5-1.5c2.9,0,5.2,0.8,6.9,2.3c1.8,1.5,2.8,3.7,3,6.5h-5.1
              c-0.1-1.6-0.6-2.8-1.3-3.5c-0.8-0.7-1.9-1.1-3.5-1.1c-1.7,0-3,0.6-3.8,1.8c-0.8,1.2-1.3,3.1-1.3,5.7v1.9c0,2.7,0.4,4.7,1.2,5.9
              s2.1,1.9,3.9,1.9c1.6,0,2.8-0.4,3.5-1.1c0.8-0.7,1.2-1.8,1.3-3.4H67z"></path>
              <path class="st0 svg-elem-4" d="M78.8,15.5l-2.7,2.9v7.1H71V0.6h5.1v11.3l2.3-3.1l6.3-8.2h6.3l-8.8,11.1l9.1,13.8h-6.1L78.8,15.5z"></path>
              <path class="st0 svg-elem-5" d="M100.4,16.8h-9.6v-4h9.6V16.8z"></path>
              <path class="st0 svg-elem-6" d="M120.4,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"></path>
              <path class="st0 svg-elem-7" d="M146.1,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"></path>
              <path class="st0 svg-elem-8" d="M151.2,25.5V0.6h7.7c2.2,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.2c1,1.8,1.5,3.9,1.5,6.2v1.1
              c0,2.3-0.5,4.4-1.4,6.2c-1,1.8-2.3,3.2-4,4.2c-1.7,1-3.7,1.5-5.9,1.5H151.2z M156.4,4.8v16.6h2.5c2,0,3.5-0.7,4.6-2
              s1.6-3.2,1.6-5.6v-1.3c0-2.5-0.5-4.4-1.6-5.8c-1-1.3-2.6-2-4.6-2H156.4z"></path>
              <path class="st0 svg-elem-9" d="M15.5,49.7H5.6v6.7h11.6v4.1H0.5V35.6h16.6v4.2H5.6v5.9h9.8V49.7z"></path>
              <path class="st0 svg-elem-10" d="M41.1,60.5H36L26,44.1v16.4h-5.1V35.6H26L36,52V35.6h5.1V60.5z"></path>
              <path class="st0 svg-elem-11" d="M66,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
              c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
              c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
              c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8H66V57.4z"></path>
              <path class="st0 svg-elem-12" d="M76.3,60.5h-5.1V35.6h5.1V60.5z"></path>
              <path class="st0 svg-elem-13" d="M102,60.5h-5.1l-10-16.4v16.4h-5.1V35.6h5.1l10,16.4V35.6h5.1V60.5z"></path>
              <path class="st0 svg-elem-14" d="M122.1,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"></path>
              <path class="st0 svg-elem-15" d="M142.5,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"></path>
              <path class="st0 svg-elem-16" d="M157.2,51.4h-4.1v9.1H148V35.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
              c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L157.2,51.4z M153.1,47.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
              c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V47.2z"></path>
              <path class="st0 svg-elem-17" d="M176.4,60.5h-5.1V35.6h5.1V60.5z"></path>
              <path class="st0 svg-elem-18" d="M202.1,60.5H197l-10-16.4v16.4h-5.1V35.6h5.1L197,52V35.6h5.1V60.5z"></path>
              <path class="st0 svg-elem-19" d="M227,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
              c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
              c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
              c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V57.4z"></path>
            </g>
          </svg>

        </span>

          <span class="clip pc">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 398.7 26" style="enable-background:new 0 0 398.7 26;" xml:space="preserve">
              <clipPath id="backendEngineeringClip1PC" clipPathUnits="objectBoundingBox">
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M0.4,25.5V0.6h8.7c3,0,5.3,0.6,6.9,1.7c1.6,1.2,2.3,2.9,2.3,5.1c0,1.2-0.3,2.3-0.9,3.2c-0.6,0.9-1.5,1.6-2.6,2
                c1.3,0.3,2.3,1,3,1.9c0.7,1,1.1,2.2,1.1,3.6c0,2.4-0.8,4.2-2.3,5.4c-1.5,1.2-3.7,1.9-6.5,1.9H0.4z M5.6,11h3.8
                c2.6,0,3.9-1.1,3.9-3.1c0-1.1-0.3-1.9-1-2.4C11.6,5,10.6,4.7,9.2,4.7H5.6V11z M5.6,14.6v6.7H10c1.2,0,2.2-0.3,2.8-0.9
                c0.7-0.6,1-1.4,1-2.4c0-2.3-1.2-3.4-3.5-3.5H5.6z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M36.7,20.3h-9L26,25.5h-5.5l9.3-24.9h4.8l9.3,24.9h-5.5L36.7,20.3z M29.1,16.2h6.2l-3.1-9.3L29.1,16.2z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M65.7,17.2c-0.2,2.7-1.2,4.8-3,6.3c-1.8,1.5-4.1,2.3-7,2.3c-3.2,0-5.7-1.1-7.5-3.2c-1.8-2.1-2.7-5.1-2.7-8.8v-1.5
                c0-2.4,0.4-4.5,1.3-6.3c0.8-1.8,2-3.2,3.6-4.2c1.6-1,3.4-1.5,5.5-1.5c2.9,0,5.2,0.8,6.9,2.3c1.8,1.5,2.8,3.7,3,6.5h-5.1
                c-0.1-1.6-0.6-2.8-1.3-3.5c-0.8-0.7-1.9-1.1-3.5-1.1c-1.7,0-3,0.6-3.8,1.8C51,7.5,50.6,9.4,50.6,12v1.9c0,2.7,0.4,4.7,1.2,5.9
                s2.1,1.9,3.9,1.9c1.6,0,2.8-0.4,3.5-1.1c0.8-0.7,1.2-1.8,1.3-3.4H65.7z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M76.7,15.5L74,18.4v7.1h-5.1V0.6H74v11.3l2.3-3.1l6.3-8.2h6.3l-8.8,11.1l9.1,13.8h-6.1L76.7,15.5z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M100.3,16.8h-9.6v-4h9.6V16.8z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M119.5,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M144.3,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M148.7,25.5V0.6h7.7c2.2,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.2c1,1.8,1.5,3.9,1.5,6.2v1.1c0,2.3-0.5,4.4-1.4,6.2
                c-1,1.8-2.3,3.2-4,4.2c-1.7,1-3.7,1.5-5.9,1.5H148.7z M153.8,4.7v16.6h2.5c2,0,3.5-0.7,4.6-2s1.6-3.2,1.6-5.6v-1.3
                c0-2.5-0.5-4.4-1.6-5.8c-1-1.3-2.6-2-4.6-2H153.8z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M194.9,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M219.8,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M243.8,22.3c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                s-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1c1.7,1.4,2.6,3.5,3,6.1
                h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6c0,2.6,0.5,4.6,1.5,5.9
                s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3H234v-3.8h9.8V22.3z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M253.3,25.5h-5.1V0.6h5.1V25.5z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M278.2,25.5h-5.1l-10-16.4v16.4H258V0.6h5.1l10,16.4V0.6h5.1V25.5z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M297.5,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M317.1,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M330.9,16.4h-4.1v9.1h-5.1V0.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L330.9,16.4z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M349.4,25.5h-5.1V0.6h5.1V25.5z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M374.3,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"/>
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M398.4,22.3c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                s-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1c1.7,1.4,2.6,3.5,3,6.1
                h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6c0,2.6,0.5,4.6,1.5,5.9
                s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V22.3z"/>
              </clipPath>
              <clipPath id="backendEngineeringClip2PC" clipPathUnits="objectBoundingBox">
                <path transform="scale(0.00250815149235, 0.038461538461538)" d="M326.9,12.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V12.2z"/>
              </clipPath>
            </svg>
          </span>

          <span class="clip sp">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 227.3 61.2" style="enable-background:new 0 0 227.3 61.2;" xml:space="preserve">
              <clipPath id="backendEngineeringClip1SP" clipPathUnits="objectBoundingBox">
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M0.5,25.5V0.6h8.7c3,0,5.3,0.6,6.9,1.7c1.6,1.2,2.3,2.9,2.3,5.1c0,1.2-0.3,2.3-0.9,3.2c-0.6,0.9-1.5,1.6-2.6,2
                c1.3,0.3,2.3,1,3,1.9c0.7,1,1.1,2.2,1.1,3.6c0,2.4-0.8,4.2-2.3,5.4c-1.5,1.2-3.7,1.9-6.5,1.9H0.5z M5.6,11h3.8
                c2.6,0,3.9-1.1,3.9-3.1c0-1.1-0.3-1.9-1-2.4C11.7,5,10.6,4.8,9.2,4.8H5.6V11z M5.6,14.7v6.7H10c1.2,0,2.2-0.3,2.8-0.9
                c0.7-0.6,1-1.4,1-2.4c0-2.3-1.2-3.4-3.5-3.5H5.6z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M37.6,20.4h-9l-1.7,5.1h-5.5l9.3-24.9h4.8l9.3,24.9h-5.5L37.6,20.4z M30,16.2h6.2l-3.1-9.3L30,16.2z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M67,17.2c-0.2,2.7-1.2,4.8-3,6.3s-4.1,2.3-7,2.3c-3.2,0-5.7-1.1-7.5-3.2c-1.8-2.1-2.7-5.1-2.7-8.8v-1.5
                c0-2.4,0.4-4.5,1.3-6.3c0.8-1.8,2-3.2,3.6-4.2c1.6-1,3.4-1.5,5.5-1.5c2.9,0,5.2,0.8,6.9,2.3c1.8,1.5,2.8,3.7,3,6.5h-5.1
                c-0.1-1.6-0.6-2.8-1.3-3.5c-0.8-0.7-1.9-1.1-3.5-1.1c-1.7,0-3,0.6-3.8,1.8c-0.8,1.2-1.3,3.1-1.3,5.7v1.9c0,2.7,0.4,4.7,1.2,5.9
                s2.1,1.9,3.9,1.9c1.6,0,2.8-0.4,3.5-1.1c0.8-0.7,1.2-1.8,1.3-3.4H67z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M78.8,15.5l-2.7,2.9v7.1H71V0.6h5.1v11.3l2.3-3.1l6.3-8.2h6.3l-8.8,11.1l9.1,13.8h-6.1L78.8,15.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M100.4,16.8h-9.6v-4h9.6V16.8z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M120.4,14.7h-9.8v6.7h11.6v4.1h-16.7V0.6h16.6v4.2h-11.5v5.9h9.8V14.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M146.1,25.5h-5.1l-10-16.4v16.4h-5.1V0.6h5.1l10,16.4V0.6h5.1V25.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M151.2,25.5V0.6h7.7c2.2,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4,4.2c1,1.8,1.5,3.9,1.5,6.2v1.1c0,2.3-0.5,4.4-1.4,6.2
                c-1,1.8-2.3,3.2-4,4.2c-1.7,1-3.7,1.5-5.9,1.5H151.2z M156.4,4.8v16.6h2.5c2,0,3.5-0.7,4.6-2s1.6-3.2,1.6-5.6v-1.3
                c0-2.5-0.5-4.4-1.6-5.8c-1-1.3-2.6-2-4.6-2H156.4z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M15.5,49.7H5.6v6.7h11.6v4.1H0.5V35.6h16.6v4.2H5.6v5.9h9.8V49.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M41.1,60.5H36L26,44.1v16.4h-5.1V35.6H26L36,52V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M66,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8H66V57.4z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M76.3,60.5h-5.1V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M102,60.5h-5.1l-10-16.4v16.4h-5.1V35.6h5.1l10,16.4V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M122.1,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M142.5,49.7h-9.8v6.7h11.6v4.1h-16.7V35.6h16.6v4.2h-11.5v5.9h9.8V49.7z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M157.2,51.4h-4.1v9.1H148V35.6h9.2c2.9,0,5.2,0.7,6.8,2c1.6,1.3,2.4,3.2,2.4,5.6c0,1.7-0.4,3.1-1.1,4.2
                c-0.7,1.1-1.8,2-3.3,2.7l5.4,10.2v0.2h-5.5L157.2,51.4z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M176.4,60.5h-5.1V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M202.1,60.5H197l-10-16.4v16.4h-5.1V35.6h5.1L197,52V35.6h5.1V60.5z"/>
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M227,57.4c-0.9,1.1-2.2,2-3.9,2.6c-1.7,0.6-3.6,0.9-5.6,0.9c-2.2,0-4-0.5-5.7-1.4c-1.6-0.9-2.9-2.3-3.8-4.1
                c-0.9-1.8-1.3-3.9-1.4-6.3v-1.7c0-2.5,0.4-4.6,1.3-6.5c0.8-1.8,2-3.2,3.6-4.2s3.4-1.4,5.5-1.4c3,0,5.3,0.7,6.9,2.1
                c1.7,1.4,2.6,3.5,3,6.1h-5c-0.2-1.4-0.7-2.5-1.5-3.1c-0.8-0.7-1.9-1-3.2-1c-1.7,0-3.1,0.7-4,2c-0.9,1.3-1.4,3.3-1.4,5.8v1.6
                c0,2.6,0.5,4.6,1.5,5.9s2.4,2,4.4,2c1.9,0,3.3-0.4,4.1-1.2v-4.3h-4.7v-3.8h9.8V57.4z"/>
              </clipPath>
              <clipPath id="backendEngineeringClip2SP" clipPathUnits="objectBoundingBox">
                <path transform="scale(0.004399472063352, 0.016339869281046)" d="M153.1,47.2h4.1c1.3,0,2.3-0.3,3-1c0.7-0.7,1.1-1.6,1.1-2.7
                c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.7-1-3.1-1h-4.1V47.2z"/>
              </clipPath>
            </svg>
          </span>

          <div id="particle2" class="inview">
            <div id="canvas2"></div>
          </div>
          <div class="white inview"></div>
          <div class="black"></div>
        </h3>

        <dl>
          <dt class="inview activeAnimation">
            <span class="text"><span class="wrap"><strong>PHP</strong></span></span>
            <span class="line"></span>
          </dt>
          <dd>
            <ul class="inview">
              <li class="inview"><span>Laravel</span></li>
              <li class="inview"><span>Wordpress</span></li>
              <li class="inview"><span>CakePHP</span></li>
              <li class="inview"><span>FuelPHP</span></li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt class="inview activeAnimation">
            <span class="text"><span class="wrap"><strong>Python</strong></span></span>
            <span class="line"></span>
          </dt>
          <dd>
            <ul class="inview">
              <li class="inview"><span>Django</span></li>
              <li class="inview"><span>Flask</span></li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt class="inview activeAnimation">
            <span class="text"><span class="wrap"><strong>Ruby</strong></span></span>
            <span class="line"></span>
          </dt>
          <dd>
            <ul class="inview">
              <li class="inview"><span>Rails</span></li>
            </ul>
          </dd>
        </dl>
      </section>

    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { onMounted, onUnmounted, onBeforeUnmount, reactive, ref } from 'vue';
import p5 from 'p5';
import { p5Setup1, drawDisactive1 } from '../assets/js/SetupP5_1';
import { p5Setup2, drawDisactive2 } from '../assets/js/SetupP5_2';
export default {
  name: 'About',
  data () {
    return {
    };
  },
  setup () {

    const resize = () => {
      elementSetup();
    };

    const state = reactive({
      isShow: false,
      isParticesShow: [],
      isElementShow: [],
      isElementActive: [],
      particleHTML: '',
      reload: 0,
      isDescriptionCenter: false
    });

    const init = () => {
      $('.block dl dt').each(function (index) {
        state.isElementShow[index] = false;
      });

      $('.inviewPathAnimation').each(function (index) {
        state.isParticesShow[index] = false;
      });

      setTimeout(() => {
        state.isShow = true;
      }, 50);

    };

    const elementSetup = () => {

      const pc = ($(window).width() > 767) ? true : false;
      let skillTitleWidthArray = new Array();
      $('.block dl dt .text .wrap strong').each(function () {
        skillTitleWidthArray.push($(this).width());
      });
      let skillTitleMaxWidth = (pc) ? Math.max.apply(Math, skillTitleWidthArray) : $('.block dl').width();
      $('.block dl dt').width(skillTitleMaxWidth);
      let skillChildTitlePL = Number($('.block dl dt').css('padding-left').replace('px', ''));
      let skillChildTitlePR = Number($('.block dl dt').css('padding-right').replace('px', ''));
      let skillBlockLeftPadding = skillChildTitlePL + skillChildTitlePR;
      let skillBlockLeftWidth = skillBlockLeftPadding + skillTitleMaxWidth;
      let skillBlockRightWidth = (pc) ? $('.block dl').width() - skillBlockLeftWidth : $('#about .aboutContents').width();
      $('.block dl dd').width(skillBlockRightWidth);
      let skillChildTitleLineMargin = (pc) ? 30 : 20;
      $('.block dl dt').each(function () {
        let lineBlockWidth = ($(this).width() + skillChildTitlePR) - $('.text', this).width();
        $('.line', this).width(lineBlockWidth - skillChildTitleLineMargin);
        $('.line', this).css({'left': (($(this).width() + skillChildTitlePR) - $('.line', this).width()) + 'px'});
      });
      let devideLength = (pc) ? 4 : 3;
      let skillChildElementWidth = skillBlockRightWidth / devideLength;
      $('.block dl dd ul li').width(skillChildElementWidth);
      $('.block dl dd ul li').height($('.block dl dt .text').height());
      $('.block dl dd ul li').css({'line-height': $('.block dl dt .text').height() + 'px'});
      $('.block dl dt .line').css({'top' : $('.block dl dt .text').height() / 2 + 'px'});

      $('.block dl dt').each(function () {
        $('.text', this).width($('.text .wrap strong', this).width() + 1);
        $('.text', this).height($('.text', this).height());
        /*
        state.isElementShow[index] = true;
        setTimeout(function () {
          state.isElementActive[index] = true;
        }, 5);
        */
      });

      if ($(window).height() > $('.aboutContents .description').height() - 100 && $(window).height() < $('.aboutContents .description').height() * 2) {
        state.isDescriptionCenter = true;
        $('.aboutContents .description').height($(window).height());
        let scrollPosition = ( $(window).height() - $('.aboutContents .description .wrap').height() ) / 2 + $('.aboutContents .description .contact').height() * 0.75;
        $('.aboutContents .description .scroll').height(scrollPosition);
        $('.aboutContents .description .scroll').addClass('show');
      } else {
        state.isDescriptionCenter = false;
        $('.aboutContents .description').height('auto');
        $('.aboutContents .description .scroll').removeClass('show');
      }

    };

    const inview = () => {
      let viewArea = window.pageYOffset + $(window).height();
      const inviews = document.querySelectorAll('.inview');
      let loop = true;
      for (let index = 0; index < inviews.length; index++) {
        if (inviews[index].getBoundingClientRect().top < viewArea) {
          if ($('.inview').eq(index).hasClass('activeAnimation')) {
            //state.isElementShow[index] = true;
            $('.inview').eq(index).addClass('show');
            setTimeout(() => {
              inviewActive(index);
              //$(this).addClass('active');
              //state.isElementActive[index] = true;
            }, 10);
          } else {
            $('.inview').eq(index).addClass('show');
          }
        }
        if ($('.inview').eq(index).hasClass('show')) {
          loop = false;
        } else {
          loop = true;
        }
      }

      if (loop) window.requestAnimationFrame(inview);
    };

    const inviewActive = (target) => {
      $('.inview').eq(target).addClass('active');
    };

    window.requestAnimationFrame(inview);

    $(window).on('resize', function () {
      resize();
    });

    onMounted(() => {
      init();
      elementSetup();
      inview();
      P5.value = new p5(p5Setup1);
      P5.value = new p5(p5Setup2);
    });

    const stopDraw = () => {
      drawDisactive1();
      drawDisactive2();
    };

    onBeforeUnmount(() => {
      $('.inview').removeClass('show');
      $('.inview').removeClass('active');
      $('#canvas1 canvas').remove();
      $('#canvas2 canvas').remove();
    });

    onUnmounted(() => {
      stopDraw();
    });

    const P5 = ref();

    return {
      state,
      P5,
      stopDraw
    };

  }
};

</script>

<style lang="scss" scoped>
#about {
  display: flex;
  justify-content: center;
  background: #000;
  color: #fff;
  font-size: .85rem;
  .aboutContents {
    position: relative;
    padding: 0 20px;
    @media screen and (min-width:640px) {
      width: 900px;
      padding: 0 4rem;
    }
    .description {
      position: relative;
      margin: 0 0 6rem;
      padding: 0;
      @media screen and (min-width:640px) {
        margin: 0 0 8rem;
        padding: 4rem 0 0;
      }
      .wrap {
        padding: 20px 0 0;
        @media screen and (min-width:380px) {
          padding: 0;
        }
      }
      h1 {
        color: #fff;
        margin: 3rem 0 0;
        font-size: .75rem;
        line-height: 1rem;
        @media screen and (min-width:380px) {
          font-size: .8rem;
          margin: 3rem 0 2.5rem;
          line-height: 1.4rem;
        }
        @media screen and (min-width:640px) {
          font-size: .9rem;
          line-height: 1.9rem;
        }
        .chat {
          opacity: 0;
        }
        .en {
          display: block;
          margin-top: 1.8rem;
          color: #fff;
          @media screen and (min-width:380px) {
            margin-top: 2rem;
          }
          @media screen and (min-width:640px) {
            margin-top: 2.2rem;
          }
        }
      }
      &.center {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        @media screen and (min-width:640px) {
          margin: 0;
          padding: 0;
        }
        h1 {
          margin: 0 0 2.5rem;
        }
      }
      &:after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        transition: all .75s .5s ease;
      }
      .scroll {
        position: absolute;
        display: none;
        min-height: 100px;
        bottom: 0;
        left: 8px;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        font-size: .7rem;
        letter-spacing: 3px;
        &:before {
          position: absolute;
          display: block;
          content: '';
          left: -8px;
          width: 1px;
          height: 100%;
          bottom: 0;
          background-color: #fff;
          animation: scrollLine 1s ease-in-out infinite;
        }
        &.show {
          display: block;
        }
      }
    }
    .block {
      margin: 6rem 0 7rem;
      @media screen and (min-width:640px) {
        margin: 6rem 0 9rem;
      }
    }

    h3 {
      position: relative;
      margin: 5rem 0 3rem;
      font-size: 4rem;
      letter-spacing: 2px;
      #particle1 {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        clip-path: url('#frontendEngineeringClip1SP');
        opacity: 0;
        overflow: hidden;
        transition: all 1s .5s ease;
        @media screen and (min-width:640px) {
          clip-path: url('#frontendEngineeringClip1PC');
        }
        #canvas1 {
          position: absolute;
          width: calc(100% + 30px);
          height: calc(100% + 30px);
          top: -15px;
          left: -15px;
          .p5Canvas {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      #particle1.show {
        opacity: 1;
      }
      #particle2 {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        clip-path: url('#backendEngineeringClip1SP');
        /*background-color: #fff;*/
        opacity: 0;
        overflow: hidden;
        transition: all 1s .5s ease;
        @media screen and (min-width:640px) {
          clip-path: url('#backendEngineeringClip1PC');
        }
        #canvas2 {
          position: absolute;
          width: calc(100% + 30px);
          height: calc(100% + 30px);
          top: -15px;
          left: -15px;
          .p5Canvas {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
      #particle2.show {
        opacity: 1;
      }
      span {
        font-weight: bold;
      }
      .title {
        position: relative;
        opacity: .5;
      }
      .svg {
        position: absolute;
        width: 100%;
      }
      svg {
        display: block;
        top: 0;
        left: 0;
      }
      .clip {
        position: relative;
        display: block;
      }
      .black {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
      }
      .white {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #fff;
        opacity: 0;
        transition: all 1s .5s ease;
      }
      .white.show {
        opacity: .2;
      }
    }
    #frontendEngineering {
      h3 {
        width: 90%;
        @media screen and (min-width:640px) {
          width: 89%;
        }
        .black {
          clip-path: url('#frontendEngineeringClip2SP');
          @media screen and (min-width:640px) {
            clip-path: url('#frontendEngineeringClip2PC');
          }
        }
        .white {
          clip-path: url('#frontendEngineeringClip1SP');
          @media screen and (min-width:640px) {
            clip-path: url('#frontendEngineeringClip1PC');
          }
        }
      }
    }
    #backendEngineering {
      h3 {
        width: 90%;
        @media screen and (min-width:640px) {
          width: 77%;
        }
        .black {
          clip-path: url('#backendEngineeringClip2SP');
          @media screen and (min-width:640px) {
            clip-path: url('#backendEngineeringClip2PC');
          }
        }
        .white {
          clip-path: url('#backendEngineeringClip1SP');
          @media screen and (min-width:640px) {
            clip-path: url('#backendEngineeringClip1PC');
          }
        }
      }
    }
    .btns {
      a {
        color: #fff;
        span {
          font-size: 1rem;
          @media screen and (min-width:380px) {
            font-size: 1.1rem;
          }
          @media screen and (min-width:640px) {
            font-size: 1.3rem;
          }
        }
      }
      div {
        width: 100%;
        &:before {
          background: #fff;
        }
      }
    }
    .block {
      dl {
        display: block;
        margin: 0 0 60px;
        @media screen and (min-width:768px) {
          display: flex;
        }
        dt {
          position: relative;
          padding: 0;
          margin-bottom: 20px;
          width: 100%;
          @media screen and (min-width:768px) {
            width: auto;
            max-width: 300px;
            margin-bottom: 0;
            padding: 0 70px 0 0;
          }
          .text {
            display: inline-block;
            position: relative;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: 1px;
            opacity: 0;
            @media screen and (min-width:640px) {
              font-size: 1.4rem;
              letter-spacing: 2px;
            }
            span {
              position: relative;
              overflow: hidden;
              strong {
                font-weight: 500;
              }
            }
          }
          .line {
            position: absolute;
            display: block;
            height: 1px;
            &:before {
              position: absolute;
              content: '';
              display: block;
              width: 0;
              height: 1px;
              background-color: #8b8a8a;
            }
          }
          &.show {
            .text {
              opacity: 1;
              .wrap {
                position: absolute;
                width: 0;
                height: 100%;
                left: 0;
                transition: all .6s ease;
                strong {
                  display: block;
                  position: absolute;
                  height: 100%;
                }
              }
            }
            .line {
              &:before {
                transition: all .4s .5s ease;
              }
            }
          }
          &.active {
            .text {
              .wrap {
                width: 100%;
              }
            }
            .line {
              &:before {
                width: 100%;
              }
            }
          }
        }
        dd {
          ul {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            &:before {
              position: absolute;
              display: block;
              top: 0;
              left: 0;
              width: 1px;
              height: 0;
              background-color: #8b8a8a;
              transition: all .45s .9s ease;
              @media screen and (min-width:768px) {
                content: '';
              }
            }
            &.show {
              &:before {
                height: 100%;
              }
            }
            li {
              position: relative;
              margin: 0;
              font-size: .75rem;
              font-weight: 300;
              letter-spacing: 2px;
              text-align: center;
              overflow: hidden;
              @media screen and (min-width:640px) {
                font-size: .9rem;
              }
              &:last-child {
                @media screen and (min-width:768px) {
                  margin: 0;
                  font-size: .9rem;
                }
              }
              &:first-child {
                &:before {
                  position: absolute;
                  content: '';
                  display: block;
                  top: 50%;
                  left: 0;
                  width: 1px;
                  height: 0;
                  background-color: #8b8a8a;
                  margin-top: -8px;
                  @media screen and (min-width:768px) {
                    display: none;
                  }
                }
              }
              &:nth-child(3n + 4) {
                &:before {
                  position: absolute;
                  content: '';
                  display: block;
                  top: 50%;
                  left: 0;
                  width: 1px;
                  height: 0;
                  background-color: #8b8a8a;
                  margin-top: -8px;
                  @media screen and (min-width:768px) {
                    display: none;
                  }
                }
              }
              &:nth-child(n + 4) {
                margin: 20px 0 0;
                @media screen and (min-width:768px) {
                  margin: 0;
                }
              }
              &:nth-child(n + 5) {
                @media screen and (min-width:768px) {
                  margin: 20px 0 0;
                }
              }
              &:before {
                transition: all .5s 1s ease;
              }
              &:after {
                position: absolute;
                content: '';
                display: block;
                top: 50%;
                right: 0;
                width: 1px;
                height: 0;
                background-color: #8b8a8a;
                margin-top: -8px;
                transition: all .5s 1s ease;
              }
              span {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                transition: all .5s 1s ease;
              }
              &.show {
                &:after {
                  height: 16px;
                }
                &:before {
                  height: 16px;
                }
                span {
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
    &.show {
      .description {
        &:after {
          width: 0;
        }
      }
    }
  }
}

@keyframes scrollLine {
  0% {
    height: 0;
    bottom: 100%;
  }
  40% {
    height: 100%;
    bottom: 0;
  }
  60% {
    height: 100%;
    bottom: 0;
  }
  90% {
    height: 0;
    bottom: 0;
  }
  100% {
    height: 0;
    bottom: 0;
  }
}

#frontendEngineering {
  .svg.pc {
    .st0{display:none;}
    .st1{display:inline;}
    .st2{fill:none;stroke:#808080;stroke-width:0.25;stroke-miterlimit:10;}
    svg .svg-elem-1 {
      stroke-dashoffset: 931px;
      stroke-dasharray: 931px;
      fill: transparent;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
    }

    svg.active .svg-elem-1 {
      stroke-dashoffset: 0;
      fill: rgb(0, 0, 0);
    }

    svg .svg-elem-2 {
      stroke-dashoffset: 104.4000015258789px;
      stroke-dasharray: 104.4000015258789px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
    }

    svg.active .svg-elem-2 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-3 {
      stroke-dashoffset: 133.77394104003906px;
      stroke-dasharray: 133.77394104003906px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
    }

    svg.active .svg-elem-3 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-4 {
      stroke-dashoffset: 124.3185806274414px;
      stroke-dasharray: 124.3185806274414px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
    }

    svg.active .svg-elem-4 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-5 {
      stroke-dashoffset: 145.1301727294922px;
      stroke-dasharray: 145.1301727294922px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
    }

    svg.active .svg-elem-5 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-6 {
      stroke-dashoffset: 93.19998168945312px;
      stroke-dasharray: 93.19998168945312px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
    }

    svg.active .svg-elem-6 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-7 {
      stroke-dashoffset: 29.199996948242188px;
      stroke-dasharray: 29.199996948242188px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
    }

    svg.active .svg-elem-7 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-8 {
      stroke-dashoffset: 128.99999237060547px;
      stroke-dasharray: 128.99999237060547px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
    }

    svg.active .svg-elem-8 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-9 {
      stroke-dashoffset: 145.13021850585938px;
      stroke-dasharray: 145.13021850585938px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
    }

    svg.active .svg-elem-9 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-10 {
      stroke-dashoffset: 127.2420883178711px;
      stroke-dasharray: 127.2420883178711px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
    }

    svg.active .svg-elem-10 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-11 {
      stroke-dashoffset: 128.99999237060547px;
      stroke-dasharray: 128.99999237060547px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
    }

    svg.active .svg-elem-11 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-12 {
      stroke-dashoffset: 145.13021850585938px;
      stroke-dasharray: 145.13021850585938px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
    }

    svg.active .svg-elem-12 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-13 {
      stroke-dashoffset: 138.46241760253906px;
      stroke-dasharray: 138.46241760253906px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
    }

    svg.active .svg-elem-13 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-14 {
      stroke-dashoffset: 62.800010681152344px;
      stroke-dasharray: 62.800010681152344px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
    }

    svg.active .svg-elem-14 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-15 {
      stroke-dashoffset: 145.14450073242188px;
      stroke-dasharray: 145.14450073242188px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
    }

    svg.active .svg-elem-15 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-16 {
      stroke-dashoffset: 129.0000228881836px;
      stroke-dasharray: 129.0000228881836px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
    }

    svg.active .svg-elem-16 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-17 {
      stroke-dashoffset: 129.0000228881836px;
      stroke-dasharray: 129.0000228881836px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
    }

    svg.active .svg-elem-17 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-18 {
      stroke-dashoffset: 133.77406311035156px;
      stroke-dasharray: 133.77406311035156px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
    }

    svg.active .svg-elem-18 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-19 {
      stroke-dashoffset: 62.800010681152344px;
      stroke-dasharray: 62.800010681152344px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
    }

    svg.active .svg-elem-19 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-20 {
      stroke-dashoffset: 145.14450073242188px;
      stroke-dasharray: 145.14450073242188px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
    }

    svg.active .svg-elem-20 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-21 {
      stroke-dashoffset: 138.44973754882812px;
      stroke-dasharray: 138.44973754882812px;
      -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s,
      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
    }

    svg.active .svg-elem-21 {
      stroke-dashoffset: 0;
    }

  }

  .svg.sp {
    .st0{fill:none;stroke:#808080;stroke-width:0.25;stroke-miterlimit:10;}

    svg .svg-elem-1 {
      stroke-dashoffset: 104px;
      stroke-dasharray: 104px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    }

    svg.active .svg-elem-1 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-2 {
      stroke-dashoffset: 132.49127197265625px;
      stroke-dasharray: 132.49127197265625px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    }

    svg.active .svg-elem-2 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-3 {
      stroke-dashoffset: 122.77367401123047px;
      stroke-dasharray: 122.77367401123047px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
    }

    svg.active .svg-elem-3 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-4 {
      stroke-dashoffset: 143.41665649414062px;
      stroke-dasharray: 143.41665649414062px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
    }

    svg.active .svg-elem-4 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-5 {
      stroke-dashoffset: 92.39998626708984px;
      stroke-dasharray: 92.39998626708984px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
    }

    svg.active .svg-elem-5 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-6 {
      stroke-dashoffset: 29.199996948242188px;
      stroke-dasharray: 29.199996948242188px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
    }

    svg.active .svg-elem-6 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-7 {
      stroke-dashoffset: 127.80003356933594px;
      stroke-dasharray: 127.80003356933594px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
    }

    svg.active .svg-elem-7 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-8 {
      stroke-dashoffset: 143.41668701171875px;
      stroke-dasharray: 143.41668701171875px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
    }

    svg.active .svg-elem-8 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-9 {
      stroke-dashoffset: 125.88975524902344px;
      stroke-dasharray: 125.88975524902344px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
    }

    svg.active .svg-elem-9 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-10 {
      stroke-dashoffset: 127.99999237060547px;
      stroke-dasharray: 127.99999237060547px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
    }

    svg.active .svg-elem-10 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-11 {
      stroke-dashoffset: 143.41665649414062px;
      stroke-dasharray: 143.41665649414062px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
    }

    svg.active .svg-elem-11 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-12 {
      stroke-dashoffset: 137.201904296875px;
      stroke-dasharray: 137.201904296875px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
    }

    svg.active .svg-elem-12 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-13 {
      stroke-dashoffset: 62px;
      stroke-dasharray: 62px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
    }

    svg.active .svg-elem-13 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-14 {
      stroke-dashoffset: 143.41665649414062px;
      stroke-dasharray: 143.41665649414062px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
    }

    svg.active .svg-elem-14 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-15 {
      stroke-dashoffset: 127.80001068115234px;
      stroke-dasharray: 127.80001068115234px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s;
    }

    svg.active .svg-elem-15 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-16 {
      stroke-dashoffset: 127.8000259399414px;
      stroke-dasharray: 127.8000259399414px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s;
    }

    svg.active .svg-elem-16 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-17 {
      stroke-dashoffset: 132.49136352539062px;
      stroke-dasharray: 132.49136352539062px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s;
    }

    svg.active .svg-elem-17 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-18 {
      stroke-dashoffset: 62.00001525878906px;
      stroke-dasharray: 62.00001525878906px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s;
    }

    svg.active .svg-elem-18 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-19 {
      stroke-dashoffset: 143.41668701171875px;
      stroke-dasharray: 143.41668701171875px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s;
    }

    svg.active .svg-elem-19 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-20 {
      stroke-dashoffset: 137.20204162597656px;
      stroke-dasharray: 137.20204162597656px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s;
    }

    svg.active .svg-elem-20 {
      stroke-dashoffset: 0;
    }
  }

}
#backendEngineering {
  .svg.pc {
    .st0{fill:none;stroke:#808080;stroke-width:0.25;stroke-miterlimit:10;}
      svg .svg-elem-1 {
        stroke-dashoffset: 138.15139770507812px;
        stroke-dasharray: 138.15139770507812px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      }

      svg.active .svg-elem-1 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-2 {
        stroke-dashoffset: 116.51800537109375px;
        stroke-dasharray: 116.51800537109375px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
      }

      svg.active .svg-elem-2 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-3 {
        stroke-dashoffset: 116.73634338378906px;
        stroke-dasharray: 116.73634338378906px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
      }

      svg.active .svg-elem-3 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-4 {
        stroke-dashoffset: 128.63109588623047px;
        stroke-dasharray: 128.63109588623047px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
      }

      svg.active .svg-elem-4 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-5 {
        stroke-dashoffset: 29.199996948242188px;
        stroke-dasharray: 29.199996948242188px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
      }

      svg.active .svg-elem-5 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-6 {
        stroke-dashoffset: 127.80000305175781px;
        stroke-dasharray: 127.80000305175781px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
      }

      svg.active .svg-elem-6 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-7 {
        stroke-dashoffset: 143.4166717529297px;
        stroke-dasharray: 143.4166717529297px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
      }

      svg.active .svg-elem-7 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-8 {
        stroke-dashoffset: 125.90135192871094px;
        stroke-dasharray: 125.90135192871094px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
      }

      svg.active .svg-elem-8 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-9 {
        stroke-dashoffset: 127.80003356933594px;
        stroke-dasharray: 127.80003356933594px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
      }

      svg.active .svg-elem-9 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-10 {
        stroke-dashoffset: 143.41668701171875px;
        stroke-dasharray: 143.41668701171875px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
      }

      svg.active .svg-elem-10 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-11 {
        stroke-dashoffset: 137.2020263671875px;
        stroke-dasharray: 137.2020263671875px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
      }

      svg.active .svg-elem-11 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-12 {
        stroke-dashoffset: 62.00001525878906px;
        stroke-dasharray: 62.00001525878906px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
      }

      svg.active .svg-elem-12 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-13 {
        stroke-dashoffset: 143.41668701171875px;
        stroke-dasharray: 143.41668701171875px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
      }

      svg.active .svg-elem-13 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-14 {
        stroke-dashoffset: 127.80000305175781px;
        stroke-dasharray: 127.80000305175781px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
      }

      svg.active .svg-elem-14 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-15 {
        stroke-dashoffset: 127.80000305175781px;
        stroke-dasharray: 127.80000305175781px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s;
      }

      svg.active .svg-elem-15 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-16 {
        stroke-dashoffset: 132.4914093017578px;
        stroke-dasharray: 132.4914093017578px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s;
      }

      svg.active .svg-elem-16 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-17 {
        stroke-dashoffset: 62.00001525878906px;
        stroke-dasharray: 62.00001525878906px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s;
      }

      svg.active .svg-elem-17 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-18 {
        stroke-dashoffset: 143.41668701171875px;
        stroke-dasharray: 143.41668701171875px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s;
      }

      svg.active .svg-elem-18 {
        stroke-dashoffset: 0;
      }

      svg .svg-elem-19 {
        stroke-dashoffset: 137.20223999023438px;
        stroke-dasharray: 137.20223999023438px;
        -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s;
        transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s;
      }

      svg.active .svg-elem-19 {
        stroke-dashoffset: 0;
      }

  }
  .svg.sp {

    .st0{fill:none;stroke:#808080;stroke-width:0.25;stroke-miterlimit:10;}

    svg .svg-elem-1 {
      stroke-dashoffset: 138.03253173828125px;
      stroke-dasharray: 138.03253173828125px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    }

    svg.active .svg-elem-1 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-2 {
      stroke-dashoffset: 116.51800537109375px;
      stroke-dasharray: 116.51800537109375px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    }

    svg.active .svg-elem-2 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-3 {
      stroke-dashoffset: 116.73635864257812px;
      stroke-dasharray: 116.73635864257812px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
    }

    svg.active .svg-elem-3 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-4 {
      stroke-dashoffset: 128.631103515625px;
      stroke-dasharray: 128.631103515625px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
    }

    svg.active .svg-elem-4 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-5 {
      stroke-dashoffset: 29.199996948242188px;
      stroke-dasharray: 29.199996948242188px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
    }

    svg.active .svg-elem-5 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-6 {
      stroke-dashoffset: 127.80000305175781px;
      stroke-dasharray: 127.80000305175781px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
    }

    svg.active .svg-elem-6 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-7 {
      stroke-dashoffset: 143.4166717529297px;
      stroke-dasharray: 143.4166717529297px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
    }

    svg.active .svg-elem-7 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-8 {
      stroke-dashoffset: 125.90135955810547px;
      stroke-dasharray: 125.90135955810547px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
    }

    svg.active .svg-elem-8 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-9 {
      stroke-dashoffset: 127.99999237060547px;
      stroke-dasharray: 127.99999237060547px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
    }

    svg.active .svg-elem-9 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-10 {
      stroke-dashoffset: 143.41665649414062px;
      stroke-dasharray: 143.41665649414062px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s;
    }

    svg.active .svg-elem-10 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-11 {
      stroke-dashoffset: 137.201904296875px;
      stroke-dasharray: 137.201904296875px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
    }

    svg.active .svg-elem-11 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-12 {
      stroke-dashoffset: 62px;
      stroke-dasharray: 62px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s;
    }

    svg.active .svg-elem-12 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-13 {
      stroke-dashoffset: 143.41665649414062px;
      stroke-dasharray: 143.41665649414062px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s;
    }

    svg.active .svg-elem-13 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-14 {
      stroke-dashoffset: 127.80001068115234px;
      stroke-dasharray: 127.80001068115234px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s;
    }

    svg.active .svg-elem-14 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-15 {
      stroke-dashoffset: 127.8000259399414px;
      stroke-dasharray: 127.8000259399414px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s;
    }

    svg.active .svg-elem-15 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-16 {
      stroke-dashoffset: 132.49136352539062px;
      stroke-dasharray: 132.49136352539062px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s;
    }

    svg.active .svg-elem-16 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-17 {
      stroke-dashoffset: 62.00001525878906px;
      stroke-dasharray: 62.00001525878906px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s;
    }

    svg.active .svg-elem-17 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-18 {
      stroke-dashoffset: 143.41668701171875px;
      stroke-dasharray: 143.41668701171875px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s;
    }

    svg.active .svg-elem-18 {
      stroke-dashoffset: 0;
    }

    svg .svg-elem-19 {
      stroke-dashoffset: 137.20204162597656px;
      stroke-dasharray: 137.20204162597656px;
      -webkit-transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s;
      transition: stroke-dashoffset 0.75s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s;
    }

    svg.active .svg-elem-19 {
      stroke-dashoffset: 0;
    }

  }
}

</style>
